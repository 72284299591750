@tailwind base;
@tailwind components;
@tailwind utilities;

@import "../../node_modules/css.gg/icons/css/home.css";
@import "../../node_modules/css.gg/icons/css/external.css";
@import "../../node_modules/css.gg/icons/css/chevron-double-right.css";
@import "../../node_modules/css.gg/icons/css/check.css";
@import "../../node_modules/css.gg/icons/css/code-slash.css";
@import "../../node_modules/css.gg/icons/css/trash.css";

/* Custom fonts */

@font-face {
  font-family: "Heebo";
  font-weight: 400;
  src: url("../assets/fonts/Heebo-Regular.ttf");
}

@font-face {
  font-family: "Heebo";
  font-weight: 700;
  src: url("../assets/fonts/Heebo-Bold.ttf");
}

@font-face {
  font-family: "Heebo";
  font-weight: 900;
  src: url("../assets/fonts/Heebo-Black.ttf");
}

/* Custom styles */

.gg-small {
  --ggs: 0.7;
}

.gg-normal {
  --ggs: 0.8;
}

.gg-check-fix:after {
  top: -2px;
  left: 3px;
}
