.gg-chevron-double-right {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    width: 22px;
    height: 22px
}

.gg-chevron-double-right::after,
.gg-chevron-double-right::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 8px;
    height: 8px;
    border-right: 2px solid;
    border-top: 2px solid;
    transform: rotate(45deg);
    top: 7px;
    right: 6px
}

.gg-chevron-double-right::after {
    right: 11px
}